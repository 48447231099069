<template>
  <section class="receiver-cdfs" v-if="receiverCdfs != null">
    <v-card class="elevation-10 pa-4">
      <v-layout row justify-space-between>
        <v-flex class="header">
          <h1 class="title">
            Olá, <strong>{{ currentUser.person.name }} ({{cpf_cnpj}})</strong>.
            Você tem {{ receiverCdfs.length }} CDF's emitidos:
          </h1>
        </v-flex>
      </v-layout>

      <v-layout align-center>
        <filter-cards
          :cards="cards"
          :activeStatus="activeStatus"
        />
      </v-layout>
    </v-card>

    <v-container
      class="mt-3"
      fluid
    >
      <v-card
        class="mb-4 elevation-3"
        v-for="(cdf, index) in cdfsFiltereds"
        :key="index"
        v-if="!isFetchingCdfs"
      >
        <v-toolbar
          color="grey lighten-4"
          flat
        >
          <h2 class="subheading font-weight-medium">
            {{ cdf.number }}
          </h2>

          <v-chip
            class="ml-5 mr-3 text-uppercase"
            text-color="white"
            color="grey darken-2"
            small
          >
            Emitido
          </v-chip>

          <span class="caption">
            {{ format(cdf.created_at, 'DD/MM/YYYY') }}
            às
            {{ format(cdf.created_at, 'HH:mm') }}
          </span>

          <v-spacer />

          <v-btn
            color="grey darken-2"
            class="text-capitalize"
            flat
            @click="showCdfPdf(cdf)"
            :disabled="isRequesting"
          >
            <v-icon left>mdi-certificate</v-icon>
            Visualizar
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text>
          <v-layout
            align-center
            wrap
            row
          >

            <v-flex class="mb-3" xs6>
              <v-subheader class="pl-0 caption text-uppercase" style="height: 32px;">
                Destinador
              </v-subheader>
              <h3 class="subheading">
                {{ cdf.receiver_data.name }}
              </h3>
              <span class="grey--text">
                {{ cdf.receiver_data.cpf_cnpj.length === 14 ? 'CNPJ' : 'CPF' }}: &nbsp;
                {{ formatCpfCnpj(cdf.receiver_data.cpf_cnpj)}}
              </span>
            </v-flex>

            <v-flex xs12 v-if="cdf.mtrs.length > 0">
              <v-subheader class="pl-0 caption text-uppercase" style="height: 32px;">
                Manifestos Incluídos
              </v-subheader>

              <v-chip
                v-for="(mtr, index) in cdf.mtrs"
                :key="index"
                class="body-2"
                text-color="white"
                color="green"
                label
                small
              >
                {{ mtr.number }}
              </v-chip>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>

      <v-layout
        v-if="pagination.totalPages > 1"
        justify-center
        align-center
      >
        <v-pagination class="align-self-center"
          v-model="pagination.activePage"
          :length="pagination.totalPages"
          color="green darken-4"
          circle
          @input="fetchGeneratorCdfs"
        />
      </v-layout>

      <empty-content
        class="mt-5"
        v-if="cdfsFiltereds.length === 0"
      >
        <v-icon class="mb-2" size="56" slot="content">mdi-inbox</v-icon>
        <div class="headline grey--text text--darken-1" slot="message">
          Não há CDF's para exibir
        </div>
      </empty-content>

      <v-layout
        v-if="isFetchingCdfs"
        fill-height
        align-content-center
        justify-center
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import { upperCase, find, isEqual } from 'lodash';
import format from 'date-fns/format';
import colors from 'vuetify/es5/util/colors';
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import FilterCards from '@/components/base/FilterCards.vue';
import EmptyContent from '@/components/base/EmptyContent.vue';
import mtrService from '@/services/mtr-service';
import cdfService from '@/services/cdf-service';

export default {
  name: 'receiver-cdfs',
  components: {
    FilterCards,
    EmptyContent,
  },
  mounted() {
    this.fetchGeneratorCdfs();
  },
  data() {
    return {
      pagination: {
        totalPages: 0,
        activePage: 1,
      },
      activeStatus: 'all',
      isRequesting: false,
      isFetchingCdfs: true,
      downloadingMtrReportId: false,
      format,
      formatCpfCnpj,
      cdfsFiltereds: [],
      upperCase,
    };
  },
  computed: {
    cards() {
      return [
        {
          title: 'TOTAL', status: 'all', amount: this.receiverCdfs.length, borderColor: '#767676', backgroundColor: colors.grey.lighten2, active: isEqual(this.activeStatus, 'all'),
        },
      ];
    },
    currentUser() {
      return this.$store.getters['auth/currentUser'];
    },
    receiverCdfs() {
      return this.cdfsFiltereds;
    },
    activeStatusLength() {
      const activeCard = find(this.cards, ['status', this.activeStatus]);
      return activeCard.amount;
    },
    cpf_cnpj() {
      return formatCpfCnpj(this.currentUser.person.cpf_cnpj);
    },
  },
  methods: {
    fetchGeneratorCdfs() {
      this.isFetchingCdfs = true;

      cdfService
        .cdfReceiver(this.pagination.activePage)
        .then((data) => {
          this.cdfsFiltereds = data.cdfs;
          this.pagination.totalPages = data.total_pages;
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isFetchingCdfs = false;
        });
    },
    showCdfPdf(cdf) {
      this.$router.push({ name: 'Ver PDF MTR', params: { id: cdf.id, document: 'cdf' } });
    },
  },
};
</script>
