<template>
  <in-layout>
    <v-container
      slot="list"
      fluid
    >
      <v-tabs
        class="list-tabs"
        slider-color="white"
        flat
      >
        <v-tab>
          <strong>Manifestos</strong>
        </v-tab>
        <v-tab>
          <strong>Certificados</strong>
        </v-tab>

        <v-tab-item>
          <receiver-mtrs />
        </v-tab-item>
        <v-tab-item>
          <receiver-cdfs />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import ReceiverMtrs from '@/components/mtr/list/ReceiverMtrs.vue';
import ReceiverCdfs from '@/components/cdf/list/ReceiverCdfs.vue';

export default {
  name: 'receiver',
  components: {
    InLayout,
    ReceiverMtrs,
    ReceiverCdfs,
  },
};
</script>
