var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _vm.canReject
                ? _c(
                    "v-btn",
                    _vm._g({ attrs: { flat: "", color: "blue" } }, on),
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "blue", left: "", size: "20" } },
                        [_vm._v("mdi-alert-circle")]
                      ),
                      _vm._v("\n      REJEITAR MTR\n    "),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "success", dark: "", flat: "" } },
            [
              _c("span", { staticClass: "headline align-center" }, [
                _vm._v("Rejeitar MTR"),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { "grid-list-md": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { md15: "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Descreva o motivo",
                              "single-line": "",
                              box: "",
                            },
                            model: {
                              value: _vm.reason,
                              callback: function ($$v) {
                                _vm.reason = $$v
                              },
                              expression: "reason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "grey", flat: "" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("\n          Cancelar\n        ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.reason },
                  on: {
                    click: function ($event) {
                      return _vm.rejectMtr(_vm.mtr.id)
                    },
                  },
                },
                [_vm._v("\n        Confirmar\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }