<template>
  <section class="receiver-mtrs" v-if="receiverMtrs != null">
    <v-card class="elevation-10 pa-4">
      <v-layout row justify-space-between>
        <v-flex class="header">
          <h1 class="title">
            Olá,
            <strong>{{ currentUser.person.name }} ({{ cpf_cnpj }})</strong>.
            Você tem {{ cardsLength.all }} MTR's emitidos:
          </h1>
        </v-flex>
      </v-layout>

      <v-layout align-center>
        <filter-cards
          @changeActive="toggleActive"
          :cards="cards"
          :activeStatus="activeStatus"
        />

        <v-flex xs3>
          <v-layout column justify-center align-end fill-height>
            <v-text-field
              append-outer-icon="mdi-magnify"
              box
              v-model="searchValue"
              class="custom-field"
              label="Nome, razão social, CPF ou CNPJ"
              append-icon="la-search"
              @input="searchMtrs"
              :loading="loading"
            />
            <v-flex>
              <v-btn
                color="primary"
                :disabled="isRequesting"
                @click="$router.push('/emitir-cdf')"
                v-if="$can('create-cdf', 'Cdf')"
              >
                <v-icon left>mdi-plus-circle-outline</v-icon>
                Emitir Certificado
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>

    <v-container fluid v-if="!isFetchingMtrs">
      <sort-mtrs :sortBy="sortBy" @selectOrder="sortList" />

      <v-layout
        class="mb-4"
        column
        justify-space-between
        v-bind:pagination.sync="pagination"
        v-for="(mtr, index) in mtrsFiltereds"
        :key="index"
        v-if="!isFetchingMtrs"
      >
        <v-toolbar dark color="green darken-4" flat dense cad>
          <v-toolbar-title class="subheading">
            <small>MTR</small>
            Nº {{ mtr.number }}
          </v-toolbar-title>

          <v-spacer />

          <v-toolbar-items>
            <v-layout align-center v-if="isToReceive(mtr.status)">
              Salvo em: {{ format(mtr.created_at, "DD/MM/YYYY") }}
            </v-layout>
            <v-layout align-center v-else-if="mtr.status === 'received'">
              Recebido em: {{ format(mtr.received_at, "DD/MM/YYYY") }}
            </v-layout>
            <v-layout
              align-center
              v-else-if="
                mtr.status === 'certified' &&
                  mtr.cdf !== null &&
                  mtr.cdf.created_at !== null
              "
            >
              Certificado emitido em:
              {{ format(mtr.cdf.created_at, "DD/MM/YYYY") }}
            </v-layout>
          </v-toolbar-items>
        </v-toolbar>

        <v-card>
          <v-card-text>
            <v-layout align-center>
              <v-flex xs5>
                <v-layout column>
                  <strong>Gerador</strong>
                  <span>{{ _.get(mtr, "generator_data.name") }}</span>
                  <v-layout
                    align-center
                    v-if="_.has(mtr, 'generator_data.cpf_cnpj')"
                  >
                    <span class="grey--text">
                      {{
                        mtr.generator_data.cpf_cnpj.length === 14
                          ? "CNPJ"
                          : "CPF"
                      }}: &nbsp;
                    </span>
                    <span class="grey--text">
                      {{ formatCpfCnpj(mtr.generator_data.cpf_cnpj) }}
                    </span>
                  </v-layout>
                </v-layout>
              </v-flex>

              <v-flex xs5>
                <v-layout column>
                  <strong>Transportador</strong>
                  <span>{{ mtr.transporter_data.name }}</span>
                  <v-layout
                    align-center
                    v-if="_.has(mtr, 'transporter_data.cpf_cnpj')"
                  >
                    <span class="grey--text">
                      {{
                        mtr.transporter_data.cpf_cnpj.length === 14
                          ? "CNPJ"
                          : "CPF"
                      }}: &nbsp;
                    </span>
                    <span class="grey--text">
                      {{ formatCpfCnpj(mtr.transporter_data.cpf_cnpj) }}
                    </span>
                  </v-layout>
                </v-layout>
              </v-flex>

              <v-flex xs2>
                <v-layout justify-center>
                  <v-chip
                    disabled
                    :color="chipStatusColor(mtr)"
                    text-color="white"
                  >
                    {{ translatedStatus(mtr.status) }}
                  </v-chip>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-divider />

          <v-card-text>
            <v-layout>
              <v-flex>
                <v-btn
                  round
                  small
                  outline
                  color="grey darken-1"
                  class="text-capitalize"
                  @click="showMtrPdf(mtr.id)"
                  :loading="downloadingMtrReportId === mtr.id"
                  :disabled="isRequesting"
                >
                  <v-icon left size="16">mdi-file-document</v-icon>
                  Visualizar MTR
                </v-btn>
                <v-btn
                  round
                  small
                  outline
                  color="grey darken-1"
                  class="text-capitalize"
                  v-if="!isToReceive(mtr.status) && mtr.status !== 'rejected'"
                  :disabled="isRequesting"
                  :loading="downloadingMtrReceivementReportId === mtr.id"
                  @click="downloadMtrReceivementReport(mtr.id)"
                >
                  <v-icon left size="16">mdi-file-document</v-icon>
                  Visualizar Relatório de Recebimento
                </v-btn>
                <v-btn
                  round
                  small
                  outline
                  color="grey darken-1"
                  class="text-capitalize"
                  v-if="mtr.status === 'certified'"
                  :disabled="isRequesting"
                  :loading="downloadingMtrCdfReportId === mtr.id"
                  @click="downloadMtrCdfReport(mtr.cdf.id)"
                >
                  <v-icon left size="16">mdi-file-document</v-icon>
                  Visualizar Certificado
                </v-btn>
              </v-flex>

              <v-spacer />

              <v-btn
                flat
                color="blue"
                v-if="mtr.status === 'received' && $can('receive-mtr', 'Mtr')"
                :disabled="isRequesting"
                @click="receiveMtr(mtr.id)"
              >
                <v-icon color="blue" left size="20">mdi-pencil</v-icon>
                EDITAR RECEBIMENTO
              </v-btn>
              <v-btn
                flat
                color="blue"
                v-if="isToReceive(mtr.status) && $can('receive-mtr', 'Mtr')"
                :disabled="isRequesting"
                @click="receiveMtr(mtr.id)"
              >
                <v-icon color="blue" left size="20">mdi-checkbox-marked</v-icon>
                RECEBER MTR
              </v-btn>

              <reject-mtr
                :mtr="mtr"
                :canReject="
                  isToReceive(mtr.status) && $can('receive-mtr', 'Mtr')
                "
                @updateList="fetchReceiverMtrs"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-layout>
      <v-layout
        v-if="pagination.totalPages >= 1 && !isFetchingMtrs"
        justify-center
        align-center
      >
        <v-pagination
          class="align-self-center"
          v-model="pagination.activePage"
          :length="pagination.totalPages"
          circle
          @input="fetchReceiverMtrs"
        />
      </v-layout>
      <empty-content class="mt-5" v-if="mtrsFiltereds.length === 0">
        <v-icon class="mb-2" size="56" slot="content">mdi-inbox</v-icon>
        <div class="headline grey--text text--darken-1" slot="message">
          Não há MTR's para exibir
        </div>
      </empty-content>
    </v-container>
    <v-container fluid v-else>
      <v-layout row fill-height align-content-center justify-center>
        <v-progress-circular indeterminate color="primary" />
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import { upperCase, find } from "lodash";
import format from "date-fns/format";
import colors from "vuetify/es5/util/colors";
import formatCpfCnpj from "@/utils/formatCpfCnpj";
import masks from "@/utils/masks";
import mtrService from "@/services/mtr-service";
import FilterCards from "@/components/base/FilterCards.vue";
import PaginationList from "@/components/base/PaginationList.vue";
import eventBus from "@/utils/eventBus";
import SortMtrs from "@/components/mtr/list/SortMtrs.vue";
import EmptyContent from "@/components/base/EmptyContent.vue";
import RejectMtr from "../receivers/RejectMtr.vue";

export default {
  name: "receiver-mtrs",
  components: {
    FilterCards,
    EmptyContent,
    SortMtrs,
    RejectMtr,
    PaginationList
  },
  mounted() {
    this.fetchReceiverMtrs();
    eventBus.$on("mtrReceived", () => {
      this.fetchReceiverMtrs();
    });
  },
  beforeDestroy() {
    eventBus.$off("mtrReceived");
  },
  data() {
    return {
      loading: false,
      pagination: {
        activePage: 1,
        totalPages: 0
      },
      activeStatus: "all",
      sortBy: "created_at",
      formatCpfCnpj,
      downloadingMtrReportId: false,
      downloadingMtrReceivementReportId: false,
      downloadingMtrCdfReportId: false,
      isFetchingMtrs: true,
      isRequesting: false,
      mtrsFiltereds: [],
      masks,
      upperCase,
      format,
      searchValue: "",
      cardsLength: {}
    };
  },
  computed: {
    cards() {
      return [
        {
          title: "TOTAL",
          status: "all",
          amount: this.cardsLength.all,
          borderColor: colors.grey.base,
          backgroundColor: colors.grey.lighten2
        },
        {
          title: "A RECEBER",
          status: ["to_receive", "received_by_temporary_storage"],
          amount:
            this.cardsLength.to_receive +
            this.cardsLength.received_by_temporary_storage,
          borderColor: colors.amber.base,
          backgroundColor: colors.amber.lighten5
        },
        {
          title: "RECEBIDOS",
          status: "received",
          amount: this.cardsLength.received,
          borderColor: colors.blue.base,
          backgroundColor: colors.blue.lighten5
        },
        {
          title: "CERTIFICADOS",
          status: "certified",
          amount: this.cardsLength.certified,
          borderColor: colors.green.base,
          backgroundColor: colors.green.lighten5
        }
      ];
    },
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
    receiverMtrs() {
      return this.mtrsFiltereds;
    },
    activeStatusLength() {
      const activeCard = find(this.cards, ["status", this.activeStatus]);
      return activeCard.amount;
    },
    cpf_cnpj() {
      return formatCpfCnpj(this.currentUser.person.cpf_cnpj);
    }
  },
  methods: {
    isToReceive(status) {
      return (
        status === "to_receive" || status === "received_by_temporary_storage"
      );
    },
    fetchReceiverMtrs() {
      const vm = this;
      this.isFetchingMtrs = true;
      mtrService
        .mtrReceiver(
          this.searchValue,
          this.pagination.activePage,
          this.activeStatus,
          this.sortBy
        )
        .then(({ data }) => {
          vm.isFetchingMtrs = false;
          vm.mtrsFiltereds = data.mtrs;
          vm.loading = false;
          vm.pagination.totalPages = data.total_pages;
          vm.cardsLength = data.cards;
        })
        .catch(err => {
          this.$store.dispatch("application/toggleSnackbar", {
            show: true,
            timeout: 6000,
            color: "error",
            message: err.message,
            messageIcon: "mdi-alert-outline"
          });
        })
        .finally(() => {
          this.isFetchingMtrs = false;
        });
    },
    searchMtrs() {
      clearTimeout(this.timeout);
      this.loading = true;
      this.timeout = setTimeout(() => {
        this.fetchReceiverMtrs();
      }, 400);
    },
    sortList(value) {
      this.sortBy = value;
      this.fetchReceiverMtrs();
    },
    receiveMtr(id) {
      this.$router.push({
        name: "Receber MTR",
        params: { id, status: "received" }
      });
    },
    chipStatusColor(mtr) {
      return (
        {
          to_receive: "amber",
          received: "blue",
          certified: "green",
          rejected: "red",
          cancelled: "red",
          received_by_temporary_storage: "amber"
        }[mtr.status] || "grey"
      );
    },
    toggleActive(card) {
      this.activeStatus = card.status;
      this.pagination.activePage = 1;
      this.fetchReceiverMtrs();
    },
    downloadMtrReceivementReport(mtrId) {
      this.downloadingMtrReceivementReportId = mtrId;
      this.isRequesting = true;
      mtrService
        .mtrReceivementReport(mtrId)
        .then(data => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = "Relatório de Recebimento de MTR.pdf";
          link.click();
        })
        .catch(err => {
          this.$store.dispatch("application/toggleSnackbar", {
            show: true,
            timeout: 6000,
            color: "error",
            message: err.message,
            messageIcon: "mdi-alert-outline"
          });
        })
        .finally(() => {
          this.downloadingMtrReceivementReportId = false;
          this.isRequesting = false;
        });
    },
    downloadMtrCdfReport(mtrId) {
      this.downloadingMtrCdfReportId = mtrId;
      this.isRequesting = true;
      mtrService
        .mtrCdfReport(mtrId)
        .then(data => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = "Certificado de Destinação Final.pdf";
          link.click();
        })
        .catch(err => {
          this.$store.dispatch("application/toggleSnackbar", {
            show: true,
            timeout: 6000,
            color: "error",
            message: err.message,
            messageIcon: "mdi-alert-outline"
          });
        })
        .finally(() => {
          this.downloadingMtrCdfReportId = false;
          this.isRequesting = false;
        });
    },
    translatedStatus(status) {
      return (
        {
          received: "RECEBIDO",
          to_receive: "A RECEBER",
          rejected: "REJEITADO",
          cancelled: "CANCELADO",
          received_by_temporary_storage: "A RECEBER"
        }[status] || "CERTIFICADO"
      );
    },
    showMtrPdf(mtrId) {
      this.$router.push({
        name: "Ver PDF MTR",
        params: { id: mtrId, document: "mtr" }
      });
    }
  }
};
</script>
<style>
.custom-field {
  min-width: 90%;
}
</style>
