<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn
        flat
        color="blue"
        v-if="canReject"
        v-on="on"
      >
        <v-icon color="blue" left size="20">mdi-alert-circle</v-icon>
        REJEITAR MTR
      </v-btn>
    </template>

    <v-card>
      <v-toolbar color="success" dark flat>
        <span class="headline align-center">Rejeitar MTR</span>
        <v-spacer />
        <v-btn flat icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap align-center>
            <v-flex md15>
              <v-textarea
                v-model="reason"
                label="Descreva o motivo"
                single-line
                box
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey"
          @click.native="dialog = false"
          flat
        >
            Cancelar
          </v-btn>
        <v-btn
          color="primary"
          :disabled="!reason"
          @click="rejectMtr(mtr.id)"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mtrService from '@/services/mtr-service';

export default {
  name: 'RejectMtr',
  props: {
    mtr: {
      default: {},
    },
    canReject: {
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      reason: '',
    };
  },
  methods: {
    rejectMtr(id) {
      mtrService.rejectMtr({
        mtrId: id,
        refusalReason: this.reason,
      })
        .then(() => {
          this.dialog = false;
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'MTR rejeitado com sucesso!',
            messageIcon: 'mdi-check',
          });
          this.$emit('updateList');
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
  },
};
</script>

<style>

</style>
